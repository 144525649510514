<template>
  <div class="flex flex-col xl:flex-row items-start mt-12 px-4">
    <div
      class="bg-white rounded-cu shadow w-full xl:w-400 xl:mr-5 mb-5 xl:mb-0"
    >
      <ul class="card-nav">
        <li
          @click="tracking('PARAMETRES_PROFIL_PUBLIC', 1)"
          :class="page === 1 ? 'bg-dokBlue-ultra text-white' : ''"
        >
          Profil public
        </li>
        <li
          @click="tracking('PARAMETRES_PERSONNALISER_ORDONNANCE', 2)"
          :class="page === 2 ? 'bg-dokBlue-ultra text-white' : ''"
        >
          <span>Personnalisation d'ordonnance </span>
        </li>
        <li
          @click="tracking('PARAMETRES_INFORMATION_CONFIDENTIELLE', 3)"
          :class="page === 3 ? 'bg-dokBlue-ultra text-white' : ''"
        >
          <span>Informations confidentielles</span>
        </li>
        <li
          @click="tracking('PARAMETRES_MOT_DE_PASSE', 4)"
          :class="page === 4 ? 'bg-dokBlue-ultra text-white' : ''"
        >
          <span>Mot de passe</span>
        </li>
        <li
          class="p-0 m-0 border-b multilist"
          :class="[5, 6, 7].includes(page) ? 'bg-dokBlue-ultra text-white' : ''"
        >
          <div class="py-3 px-1 flex justify-between">
            <span
              class="flex-1"
              @click="
                dropdown = !dropdown;
                tracking('PARAMETRES_PAIMENT');
              "
              >Paiements et abonnement</span
            >
            <i
              :class="!dropdown ? 'far fa-plus-square' : 'far fa-minus-square'"
              @click="dropdown = !dropdown"
            ></i>
          </div>
          <ul v-show="dropdown">
            <li
              @click="page = 5"
              :class="page === 5 ? 'bg-dokBlue-lighter text-dokBlue-ultra' : ''"
              class="border-t font-EffraR"
            >
              <span>Informations bancaires</span>
            </li>
            <li
              @click="page = 6"
              :class="page === 6 ? 'bg-dokBlue-lighter text-dokBlue-ultra' : ''"
              class="border-b-0 font-EffraR"
            >
              <span>Abonnements</span>
            </li>
            <li
              @click="page = 7"
              :class="page === 7 ? 'bg-dokBlue-lighter text-dokBlue-ultra' : ''"
              class="border-t font-EffraR"
            >
              <span>Historique des téléconsultations</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- Content -->
    <div class="bg-white rounded-cu shadow w-full">
      <div class="w-full" v-if="user._id">
        <profile v-if="page === 1"></profile>
        <ordonnance v-if="page === 2"></ordonnance>
        <informations v-if="page === 3"></informations>
        <password v-if="page === 4"></password>
        <paiements v-if="page === 5"></paiements>
        <subscriptions v-if="page === 6"></subscriptions>
        <consultation v-if="page === 7"></consultation>
      </div>
    </div>
  </div>
</template>

<script>
const CitiesSelect = () => import("@/views/global-components/CitiesSelect");
const password = () => import("@/views/dashboard/users/password");
const informations = () => import("@/views/dashboard/settings/informations");
const ordonnance = () => import("@/views/dashboard/settings/ordonnance");
const paiements = () => import("@/views/dashboard/settings/paiements");
const subscriptions = () => import("@/views/dashboard/settings/subscriptions");
const profile = () => import("@/views/dashboard/settings/profile");
const consultation = () => import("@/views/dashboard/settings/consultation");

export default {
  data() {
    return {
      dropdown: false,
      user: {
        professionalInfo: {
          INPE: null,
        },
        landingPage: {
          address: {},
        },
      },
      page: 1,
    };
  },
  async mounted() {
    let user = await this.$auth.getUserInfo();
    if (!user.bankInfo) {
      user.bankInfo = { name: null, IBAN: null };
    }
    this.user = user;
  },
  methods: {
    async tracking(item, page) {
      this.page = page;
      this.$performTracking({
        data: {
          id: this.user._id,
          route: item,
          action: "GET",
          data: {},
        },
      });
    },
  },
  components: {
    CitiesSelect,
    password,
    ordonnance,
    paiements,
    subscriptions,
    informations,
    profile,
    consultation,
  },
};
</script>

<style lang="scss">
.gender-toggle {
  label {
    @apply text-gray-700;
  }
  input[type="radio"] {
    &:checked + label {
      @apply bg-dokBlue-ultra text-white;
    }
  }
}

.card-upload {
  .actions {
    @apply hidden;
  }
  &:hover {
    .actions {
      @apply block;
      @apply flex;
      @apply flex-col-reverse;
      @apply absolute;
      @apply z-1000;
      span {
        @apply bg-blackdok;
        @apply my-2;
        @apply py-2;
        @apply px-3;
        @apply rounded-cu;
        @apply text-white;
        @apply cursor-pointer;
        @apply text-base;
        @apply text-left;
        @apply flex;
        @apply items-center;
        i {
          @apply mr-2;
        }
      }
    }

    .logo {
      @apply opacity-25;
    }
  }
}

.card-nav > li {
  font-size: 16px;
  @apply border-0 font-semibold border-b border-solid border-gray-400 py-3 px-2 text-blackdok cursor-pointer;
  ul {
    li {
      @apply bg-gray-100 border-0 border-b border-solid border-gray-500  font-normal py-3 px-2 text-blackdok cursor-pointer;
    }
  }
}
</style>
